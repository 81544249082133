import "ts-polyfill"
import $ from "jquery";
window.$ = $;
window.jQuery = $;

import "bootstrap"

import "./toggleClass"
import "../modules/newsroom/app/assets/javascripts/basket"
import "../modules/newsroom/app/assets/javascripts/nr2"
import { getCookie, createCookie } from "../modules/newsroom/app/assets/javascripts/util/cookies"
import Stickyfill from "stickyfilljs"
import moment from "moment"
import "daterangepicker"
import imagesLoaded from "imagesloaded"
import Masonry from "masonry-layout"
import jQueryBridget from 'jquery-bridget';
import toastr from "toastr";

toastr.options.closeButton = true;

// make Masonry a jQuery plugin
jQueryBridget('masonry', Masonry, $);
// make imagesLoaded a jQuery plugin
jQueryBridget('imagesLoaded', imagesLoaded, $);

// ====================================================================================================================
// Utility
// ====================================================================================================================
function updateQueryStringParameter(uri, key, value) {
  const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  const separator = uri.indexOf('?') !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + "=" + value + '$2');
  }
  else {
    return uri + separator + key + "=" + value;
  }
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

declare global {
  interface Array<T> {
    unique(): Array<T>;
  }
}

Array.prototype.unique = function() {
  return this.filter((item, index) => this.indexOf(item) === index);
};

declare global {
  interface Window {
    initMasonryGrids();
  }
}

function getParameterByName(name){
  const regexS = "[\\?&]"+name+"=([^&#]*)",
    regex = new RegExp( regexS ),
    results = regex.exec( window.location.search );
  if(results == null){
    return "";
  } else{
    return decodeURIComponent(results[1].replace(/\+/g, " "));
  }
}

function getUrlParams() {
  let match,
    plus   = /\+/g,
    search = /([^&=]+)=?([^&]*)/g,
    decode = function(s) { return decodeURIComponent(s.replace(plus, " ")) },
    query  = window.location.search.substring(1),
    params = {};

  while (match = search.exec(query)) {
    params[decode(match[1])] = decode(match[2]);
  }

  params["get"] = function(name) {
    return (params.hasOwnProperty(name) ? params[name] : '');
  };

  return params;
}

function removeParameter(url, parameter) {
  //prefer to use l.search if you have a location/link object
  const urlparts = url.split('?');
  if (urlparts.length>=2) {

    const prefix = encodeURIComponent(parameter)+'=';
    const pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (let i = pars.length; i-- > 0;) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    url = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : "");
    return url;
  } else {
    return url;
  }
}

function showLicenseAgreementModalIfNecessary() {
  // License Agreement Accept/Decline Modal
  if( getParameterByName('la') === "1" ) {
    $.get("/licenses/default")
      .done(function(modalBody) {
        $("#licenseModal").data("downloadUrl", getParameterByName("downloadUrl"));
        $("#licenseModal .modal-dialog .modal-content").html(modalBody);
        $("#licenseModal").modal('show');
      })
  }
}


// ====================================================================================================================
// header.js
// ====================================================================================================================
$(function() {
  $('ul.nav > li.hover-reveal').on("click",function(){
    $(this).closest("ul.navbar-nav").find("ul").hide();
    const link = $(this).find("a");
    if($(link).length !== 0) {
      if($(link).attr("target") !== "_blank") {
        document.location.href = $(link).attr("href");
      } else {
        window.open($(link).attr("href"), '_blank');
      }
    }
  });

  $("#globalHeader")
    .on("show.bs.collapse", function() {
      $("#globalHeader").addClass("active");
    })
    .on("hide.bs.collapse", function() {
      $("#globalHeader").removeClass("active");
    });

  //
  $(".market-navbar .dropdown").on("show.bs.dropdown", function(e) {
    const $parentItem = $(e.currentTarget);
    const $subMenu = $parentItem.children("ul.dropdown-menu");

    $subMenu.css('left', (($subMenu.width() / 2 * -1) + $parentItem.width() / 2) + 'px');
  });
});

$(document).on('click', '.embargo-overlay__button', function(e) {
  $(this).parent('.embargo-overlay').remove();
  return false;
});


// ====================================================================================================================
// Date Range Dropdown in contentTypeTabBar
// ====================================================================================================================
$(() => {
  const $startDateSinglePicker = $('input[name="startDateSingle"]');
  const $endDateSinglePicker = $('input[name="endDateSingle"]');

  let endDateSelected = false;
  let startDateSelected = false;

  const validateForm = function() {
    if(startDateSelected && endDateSelected) {
      $("button.apply-date-filter").removeAttr("disabled")
    } else {
      $("button.apply-date-filter").attr("disabled", "disabled");
    }
  };

  if($startDateSinglePicker.length && $endDateSinglePicker.length) {
    $startDateSinglePicker.daterangepicker({
      parentEl: "#daterange-desktop__dropdown",
      autoUpdateInput: false,
      singleDatePicker: true,
      maxYear: parseInt(moment().format('YYYY'), 10)
    }, function(start, end, label) {
      $startDateSinglePicker.data('daterangepicker').autoUpdateInput = true;
      startDateSelected = true;

      $endDateSinglePicker.data('daterangepicker').minDate = start;
      $startDateSinglePicker.val(start.format('MM/DD/YYYY'));

      validateForm();
    });

    $endDateSinglePicker.daterangepicker({
      parentEl: "#daterange-desktop__dropdown",
      autoUpdateInput: false,
      singleDatePicker: true,
      maxYear: parseInt(moment().format('YYYY'), 10)
    }, function(start, end, label) {
      $endDateSinglePicker.data('daterangepicker').autoUpdateInput = true;
      endDateSelected = true;

      $startDateSinglePicker.data('daterangepicker').maxDate = start;
      $endDateSinglePicker.val(start.format('MM/DD/YYYY'));

      validateForm();
    });
  }

  $("button.refine-search-quick-filter").on("click", function() {
    $startDateSinglePicker.data('daterangepicker').autoUpdateInput = true;
    $endDateSinglePicker.data('daterangepicker').autoUpdateInput = true;

    switch($(this).data("filter-name")) {
      case "7 days":
        $startDateSinglePicker.data('daterangepicker').setStartDate(moment().subtract(6, 'days'));
        break;

      case "30 days":
        $startDateSinglePicker.data('daterangepicker').setStartDate(moment().subtract(29, 'days'));
        break;

      case "6 months":
        $startDateSinglePicker.data('daterangepicker').setStartDate(moment().subtract(6, 'month'));
        break;

      case "1 year":
        $startDateSinglePicker.data('daterangepicker').setStartDate(moment().subtract(1, 'year'));
        break;

      case "12 months":
        $startDateSinglePicker.data('daterangepicker').setStartDate(moment().subtract(12, 'month'));
        break;

      case "18 months":
        $startDateSinglePicker.data('daterangepicker').setStartDate(moment().subtract(18, 'month'));
        break;

      case "All Time":
        $("button.clear-date-filter").trigger("click");
        return false;
    }

    $endDateSinglePicker.data('daterangepicker').setStartDate(moment());

    startDateSelected = true;
    endDateSelected = true;

    validateForm();

    $("button.apply-date-filter").trigger("click");
  });

  $("button.apply-date-filter").on("click", function() {
    const startDate = $startDateSinglePicker.data('daterangepicker').startDate;
    const endDate = $endDateSinglePicker.data('daterangepicker').startDate;

    $("#filterForm input#startDay").val(startDate.date());
    $("#filterForm input#startMonth").val(startDate.month() + 1);
    $("#filterForm input#startYear").val(startDate.year());

    $("#filterForm input#endDay").val(endDate.date());
    $("#filterForm input#endMonth").val(endDate.month() + 1);
    $("#filterForm input#endYear").val(endDate.year());

    paginatedSearch();
  });

  $("button.clear-date-filter").on("click", function() {
    startDateSelected = false;
    endDateSelected = false;

    $startDateSinglePicker.data('daterangepicker').maxDate = null;
    $endDateSinglePicker.data('daterangepicker').maxDate = null;

    $startDateSinglePicker.val("");
    $endDateSinglePicker.val("");
    $startDateSinglePicker.data('daterangepicker').autoUpdateInput = false;
    $endDateSinglePicker.data('daterangepicker').autoUpdateInput = true;

    $("#filterForm input#startDay").val("");
    $("#filterForm input#startMonth").val("");
    $("#filterForm input#startYear").val("");

    $("#filterForm input#endDay").val("");
    $("#filterForm input#endMonth").val("");
    $("#filterForm input#endYear").val("");

    validateForm();
    paginatedSearch();
  });

  $("#startDateSingleIcon").on("click",() => $startDateSinglePicker.trigger("focus"));
  $("#endDateSingleIcon").on("click",() => $endDateSinglePicker.trigger("focus"));

  $(document).on("click", (e) => {
    const $target = $(e.target);

    if ($target.parents(".dropdown").length ||
        $target.parents(".calendar-table").length ||
        $target.parents(".table-condensed").length) { return }
    $(".dropdown").removeClass("open");
  });

  const initialStartDate = new Date([$("#filterForm input#startYear").val(), $("#filterForm input#startMonth").val(), $("#filterForm input#startDay").val()].join("/"));
  const initialEndDate = new Date([$("#filterForm input#endYear").val(), $("#filterForm input#endMonth").val(), $("#filterForm input#endDay").val()].join("/"));

  if(isValidDate(initialStartDate) && isValidDate(initialEndDate)) {
    $startDateSinglePicker.data('daterangepicker').autoUpdateInput = true;
    $endDateSinglePicker.data('daterangepicker').autoUpdateInput = true;

    startDateSelected = true;
    endDateSelected = true;

    $startDateSinglePicker.data('daterangepicker').setStartDate(initialStartDate);
    $endDateSinglePicker.data('daterangepicker').setStartDate(initialEndDate);
  }

  validateForm();
});


// ====================================================================================================================
// site/basket.js
// ====================================================================================================================
$(document).on("basket.change", function(e, count) {
  let message = "{0}";

  if (count === 0) {
    $(".clientBasketItemCount")
      .removeClass("clientBasketItemCount--full")
      .html("");
  } else if (count === 1) {
    message = "1";

    $(".clientBasketItemCount")
      .addClass("clientBasketItemCount--full")
      .html(message);
  } else {
    message = "{0}";

    $(".clientBasketItemCount")
      .addClass("clientBasketItemCount--full")
      .html(message.replace("{0}", count));
  }
});

window.localBasketDataStore = new LocalBasketStore();

if ($("body.authenticated").length > 0) {
  // User is authenticated
  window.basket = new Basket(new RemoteBasketStore(window.localBasketDataStore));
} else {
  // Guest
  window.basket = new Basket(localBasketDataStore);
}


// ====================================================================================================================
// default/navHeader
// ====================================================================================================================

$(function() {
  if($(".site--honda").length > 0) {
    let menuOpen = false;

    $(".site--honda .hamburger").on("click", function() {
      menuOpen = true;
      $("#side-navbar").toggleClass("active");
      return false;
    });

    $(".site--honda .close-nav").on("click",function() {
      menuOpen = false;
      $("#side-navbar").removeClass("active");
      return false;
    });

    const specifiedElement = document.getElementById('side-navbar');
    if(specifiedElement !== null) {
      document.addEventListener('click', function(event) {
        const isClickInside = specifiedElement.contains(event.target);
        if (menuOpen && !isClickInside) {
          menuOpen = false;
          $("#side-navbar").removeClass("active");
        }
      });
    }

    // Search Suggestions
    var flattenedChannelTree = null;
    var $searchInput = $("input#searchInput");
    var $searchSuggestions = $("div#suggestions");
    var $searchSuggestionsList = $searchSuggestions.find("ul");
    var marketRoutePrefix = document.body.getAttribute("data-market-route-prefix");
    var KEY_ARROW_UP = 38;
    var KEY_ARROW_DOWN = 40;
    var KEY_ENTER = 13;
    var selectedSuggestionIndex = -1;
    var suggestions = [];

    function updateSuggestions() {
      suggestions = [];
      $selectedItem = null
      selectedSuggestionIndex = -1;

      for(var i = 0; i < flattenedChannelTree.length; i++) {
        if(flattenedChannelTree[i][1].includes($searchInput.val().toLowerCase())) {
          suggestions.push(flattenedChannelTree[i]);
        }
      }

      $searchSuggestionsList.empty();

      if(suggestions.length > 0 && $searchInput.val().length > 1) {
        console.log("Found " + suggestions.length + " matches.");
        $searchSuggestions.show();
        for(var i = 0; i < suggestions.length; i++) {
          $searchSuggestionsList.append("<li><a href='/" + marketRoutePrefix + "/channels/" + suggestions[i][0] + "'>" + suggestions[i][2] + "</a></li>")
        }
      } else {
        console.log("No matches found for " + $searchInput.val().toLowerCase());
        $searchSuggestions.hide();
      }
    }

    var $selectedItem = null;

    var selectSuggestion = function(direction) {
      // console.log(direction, selectedSuggestionIndex, suggestions.length);

      if (selectedSuggestionIndex === -1 && direction === 1) {
        // Select First Item
        $searchSuggestionsList.find("li").removeClass("selected");
        $selectedItem = $searchSuggestionsList.find("li:first-child").addClass("selected");
        selectedSuggestionIndex = 0;
      } else if (selectedSuggestionIndex === -1 && direction === -1) {
        // Select Last Item
        $searchSuggestionsList.find("li").removeClass("selected");
        $searchSuggestionsList.find("li").removeClass("selected");
        $selectedItem = $searchSuggestionsList.find("li:last-child").addClass("selected");
        selectedSuggestionIndex = suggestions.length - 1;
      } else if (direction === 1) {
        // Select Next Item
        if ((selectedSuggestionIndex + 1) <= (suggestions.length - 1)) {
          $searchSuggestionsList.find("li").removeClass("selected");
          $selectedItem = $selectedItem.next().addClass("selected");
          selectedSuggestionIndex += 1;
        }

      } else if (direction === -1) {
        // Select Previous Item
        if ((selectedSuggestionIndex - 1) >= 0) {
          $searchSuggestionsList.find("li").removeClass("selected");
          $selectedItem = $selectedItem.prev().addClass("selected");
          selectedSuggestionIndex -= 1;
        } else {
          $selectedItem.removeClass("selected");
          $selectedItem = null;
          selectedSuggestionIndex = -1;
        }
      }

    }

    $searchInput.on("keydown",function(e) {
      e = e || window.event;

      if (flattenedChannelTree !== null) {

        switch(e.keyCode) {
          case KEY_ARROW_UP:
            selectSuggestion(-1);
            break;
          case KEY_ARROW_DOWN:
            selectSuggestion(1);
            break;
          case KEY_ENTER:
            window.location = $selectedItem.find("a").attr("href");
            return false;
          default:
            updateSuggestions();
        }
      }
    });

    $searchInput.on("focus", function(e) {
      if($searchSuggestionsList.get()[0].childNodes.length > 0) {
        $searchSuggestions.show();
      }
    });

    $searchInput.on("blur", function(e) {
      setTimeout(function() { $searchSuggestions.hide(); }, 120)
    });

    $("#searchModal").on("shown.bs.modal", function() {

      if(flattenedChannelTree === null) {
        var channelsUrl = "/" + marketRoutePrefix + "/channels.json";
        $.get(channelsUrl, function(channels) {
          flattenedChannelTree = channels;
          updateSuggestions();
        });
      }

      $("#searchInput").focus();

      // check channel constraints according to url parameters
      getParameterByName("channelsConstraint").split(",").map((a, b, c) => {
        if(a.length > 0) {
          $("ul.search-divisions--list input[value="+a+"]").attr("checked", "checked");
        }
      });
      $("input#channels-constraint").val(getParameterByName("channelsConstraint"));

      // search modal channel checkboxes
      $("li.search-divisions--list-item").on("click", function(e) {
        const newChannelsConstraint = [];
        $("li.search-divisions--list-item input:checked").map((a, b, c) => {
          newChannelsConstraint.push(b.value);
        });
        $("input#channels-constraint").val(newChannelsConstraint.join(","));
      });

      const setSortOrder = function(option) {
        const newOrder = option.innerText;
        $("div.search-results-filter li").show();
        $(option).hide();
        $("span.search-results-filter--option").text(newOrder);
        $("input#search-sort-order").val($(option).data("sort-order"));
      }

      // set search modal results order according to url parameters
      const urlSortOrderParameter = getParameterByName("sortOrder");
      if(urlSortOrderParameter.length > 0) {
        const urlSortOrderOption = $("div.search-results-filter li[data-sort-order="+urlSortOrderParameter+"]");
        setSortOrder(urlSortOrderOption[0]);
      }

      // search modal results order
      $("div.search-results-filter li").on("click", function(e) {
        setSortOrder(this);
      });

      // set search modal date constraints from url parameters
      $(".search-calendar input#startYear").val(getParameterByName('startYear'));
      $(".search-calendar input#startMonth").val(getParameterByName('startMonth'));
      $(".search-calendar input#startDay").val(getParameterByName('startDay'));
      $(".search-calendar input#endYear").val(getParameterByName('endYear'));
      $(".search-calendar input#endMonth").val(getParameterByName('endMonth'));
      $(".search-calendar input#endDay").val(getParameterByName('endDay'));

      const startDateFromQuery = getParameterByName('startMonth') + "/" + getParameterByName('startDay') + "/" + getParameterByName('startYear').substring(2, 4);
      const endDateFromQuery = getParameterByName('endMonth') + "/" + getParameterByName('endDay') + "/" + getParameterByName('endYear').substring(2, 4);

      if(startDateFromQuery != "//" && endDateFromQuery != "//") {
        $(".search-calendar input#startDateSearchModal").val(startDateFromQuery);
        $(".search-calendar input#endDateSearchModal").val(endDateFromQuery);
      }

      // search modal datepickers
      const $startDateSearchModalPicker = $('input#startDateSearchModal');
      const $endDateSearchModalPicker = $('input#endDateSearchModal');

      let searchModalStartDateSelected = startDateFromQuery !== "//";
      let searchModalEndDateSelected = endDateFromQuery !== "//";

      const validateSearchModalDateRange = function() {
        if(searchModalStartDateSelected && searchModalEndDateSelected) {
          $(".search-calendar input#startDay").attr("disabled", false);
          $(".search-calendar input#startMonth").attr("disabled", false);
          $(".search-calendar input#startYear").attr("disabled", false);
          $(".search-calendar input#endDay").attr("disabled", false);
          $(".search-calendar input#endMonth").attr("disabled", false);
          $(".search-calendar input#endYear").attr("disabled", false);
        }
      };

      validateSearchModalDateRange();

      let monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ]

      if (window.location.pathname.match(/^\/fr-CA/)) {
        monthNames = [
          "Jan",
          "Fév",
          "Mars",
          "Avr",
          "Mai",
          "Juin",
          "Juil",
          "Aout",
          "Sep",
          "Oct",
          "Nov",
          "Déc"
        ]
      }

      $startDateSearchModalPicker.daterangepicker({
        "locale": {
          "monthNames": monthNames;
        },

        drops: 'up',
        autoUpdateInput: false,
        singleDatePicker: true,
        maxYear: parseInt(moment().format('YYYY'), 10)
      }, function(start, end, label) {
        $startDateSearchModalPicker.data('daterangepicker').autoUpdateInput = true;
        searchModalStartDateSelected = true;

        $endDateSearchModalPicker.data('daterangepicker').minDate = start;
        $startDateSearchModalPicker.val(start.format('MM/DD/YYYY'));

        $(".search-calendar input#startDay").val(start.date());
        $(".search-calendar input#startMonth").val(start.month() + 1);
        $(".search-calendar input#startYear").val(start.year());

        validateSearchModalDateRange();
      });

      $endDateSearchModalPicker.daterangepicker({
        "locale": {
          "monthNames": monthNames;
        },

        drops: 'up',
        autoUpdateInput: false,
        singleDatePicker: true,
        maxYear: parseInt(moment().format('YYYY'), 10)
      }, function(date, end, label) {
        $endDateSearchModalPicker.data('daterangepicker').autoUpdateInput = true;
        searchModalEndDateSelected = true;

        $startDateSearchModalPicker.data('daterangepicker').maxDate = date;
        $endDateSearchModalPicker.val(date.format('MM/DD/YYYY'));

        $(".search-calendar input#endDay").val(date.date());
        $(".search-calendar input#endMonth").val(date.month() + 1);
        $(".search-calendar input#endYear").val(date.year());

        validateSearchModalDateRange();
      });

      $("#startDateSearchModalIcon").on("click",() => $startDateSearchModalPicker.trigger("focus"));
      $("#endDateSearchModalIcon").on("click",() => $endDateSearchModalPicker.trigger("focus"));
    });

    let oldAction = null;

    $("[data-toggle=modal][data-search-form-action]").on("click",function(e) {
      const searchAction = e.target.getAttribute("data-search-form-action");
      oldAction = $("#searchModal form").attr("action");

      $("#searchModal form").attr("action", searchAction);
    });

    $("#searchModal").on("hidden.bs.modal", function() {
      if(oldAction != null) {
        $("#searchModal form").attr("action", oldAction);
        oldAction = null;
      }
    });
  }
});


// ====================================================================================================================
// acura/navHeader
// ====================================================================================================================

$(() => {
  $(".site--acura .hamburger").on("click",function() {
    $("#side-navbar").toggleClass("active");
  });

  $(".site--acura .close-nav").on("click",function() {
    $("#side-navbar").removeClass("active");
  });
});


// ====================================================================================================================
// modals.js
// ====================================================================================================================

let channelsConstraintQuerystring = "";

if (getParameterByName("channelsConstraint").length > 0) {
  channelsConstraintQuerystring = `?channelsConstraint=${getParameterByName("channelsConstraint")}`
}

$(document).on("hidden.bs.modal", "#media-modal", () => {
  const newUri = removeParameter(document.location.href, "modal");
  history.replaceState(null, document.title, newUri);
});

function makeModal(target: string, show: boolean, ids: string[], goToShare: boolean): Promise<string> {
  const requestUrl = target + (target.match(/\?/) === null ? "?" : "&") + "xhr=1";

  return new Promise((resolve, reject) => {
    $.ajax({
      url: requestUrl,
      dataType: "html",
      success: function(data) {
        const $data = $(data);
        const contentId: string = $data.data("basket-item-key");
        const contentType = $data.data("basket-item-type").toLowerCase();
        const first = $("#media-modal").length === 0;

        let appendThisPreview, appendTo;

        if (first) {
          appendThisPreview = $data;
          appendTo = $("body");
        } else {
          appendThisPreview = $data.find("div.modal-content-preview");
          $("body").append($("<div></div>").append($data).find("div.share-modal"));
          appendTo = $("div.modal-dialog-preview");
        }

        appendTo.append(appendThisPreview.enableBasketControls());

        if (show && !goToShare) {
          $("body")
            .find("#media-modal")
            .modal("show")
            .on('hidden.bs.modal', function () {
              $("#media-modal, div.modal-backdrop").remove();
            });

          const newUri = updateQueryStringParameter(document.location.href, "modal", null);
          history.replaceState(null, document.title, newUri);
        } else {
          if(goToShare) {
            $("body").find("div#share-modal-" + contentId).modal('show').on('hidden.bs.modal', function() {
              $("#media-modal, div#share-modal-" + contentId + ", div.modal-backdrop").remove();
            });
          }
          $("div.modal-content[data-basket-item-key=" + contentId + "]").hide();
        }

        if (contentType === "video") {
          const videoURI = $(data).find("div#video-player-" + contentId).data("uri");
          const keyframeURI = $(data).find("div#video-player-" + contentId).data("keyframe-uri");

          jwplayer("video-player-" + contentId).setup({
            file: videoURI,
            width: "100%",
            aspectratio: "16:9",
            autostart: false,
            image: keyframeURI
          });
        }

        const nextId = ids[ids.indexOf(contentId) + 1];
        const prevId = ids[ids.indexOf(contentId) - 1];

        const prefetch = function(fetchId) {
          if(typeof(fetchId) !== "undefined") {
            // if the preview hasn't already been loaded
            if ($("div.modal-content[data-basket-item-key=" + fetchId + "]").length === 0) {
              const target = $("div[data-basket-item-key=" + fetchId + "]").find("a[data-toggle=" + contentType + "-modal]").attr("href");
              makeModal(target, false, ids, false);
            }
          }
        };

        if (show) {
          const newUri = updateQueryStringParameter(document.location.href, "modal", contentId);
          history.replaceState(null, document.title, newUri);

          prefetch(prevId);
          prefetch(nextId);
        }

        const hideIndex = ids.indexOf(contentId);

        // disable previous and next buttons as appropriate
        if (hideIndex === 0) { appendThisPreview.find("div#prev").addClass("disabled"); }
        if (hideIndex === ids.length - 1) { appendThisPreview.find("div#next").addClass("disabled"); }

        appendThisPreview.find("div#prev").click(function(){
          if (contentType === "video") {
            jwplayer("video-player-" + contentId).stop();
          }

          const newUri = updateQueryStringParameter(document.location.href, "modal", contentId);
          history.replaceState(null, document.title, newUri);

          if (hideIndex > 0 && $("div.modal-content[data-basket-item-key=" + prevId + "]").length > 0) {
            $("div.modal-content[data-basket-item-key=" + contentId + "]").hide();
            $("div.modal-content[data-basket-item-key=" + prevId + "]").show();
            prefetch(ids[hideIndex - 2]);
          }
        });

        appendThisPreview.find("div#next").click(function(){
          if (contentType === "video") {
            jwplayer("video-player-" + contentId).stop();
          }

          const newUri = updateQueryStringParameter(document.location.href, "modal", contentId);
          history.replaceState(null, document.title, newUri);

          if (hideIndex < ids.length -1 && $("div.modal-content[data-basket-item-key=" + nextId + "]").length > 0) {
            $("div.modal-content[data-basket-item-key=" + contentId + "]").hide();
            $("div.modal-content[data-basket-item-key=" + nextId + "]").show();
            prefetch(ids[hideIndex + 2]);
          }
        });

        resolve(contentId);
      }
    });
  });
}


const paginatedSets: Map<string, string[]> = $("[data-paginated-set-id]").toArray().reduce((p, c, i, a) => {
  p.set(c.getAttribute("data-paginated-set-id"),
        $(c).find("[data-basket-item-type]")
            .toArray()
            .map((e) => e.getAttribute("data-basket-item-key"))
            .unique());

  return p;
}, new Map());

function paginatedSetIdForContentId(contentId: string) {
  const result = [...paginatedSets].find(([k, v]) => v.indexOf(contentId) > -1);

  if(result !== undefined) {
    return result[0];
  } else {
    return undefined;
  }
}

function paginatedSetForContentId(contentId: string): string[] {
  const paginatedSetId = paginatedSetIdForContentId(contentId);
  if(paginatedSetId !== undefined) {
    return paginatedSets.get(paginatedSetId)
  } else {
    return [contentId];
  }
}

const querystringModal = document.location.href.match(/modal=((photo|video)-[^&\b]*)?/);
if(querystringModal !== null) {
  let contentId = querystringModal[1];
  let idSet = paginatedSetForContentId(contentId);

  makeModal("/" + querystringModal[2] + "s/" + querystringModal[1], true, idSet, false)
    .then((contentId) => {
      console.log(`wow ${contentId}`);
      showLicenseAgreementModalIfNecessary()
    });
} else {
  showLicenseAgreementModalIfNecessary();
}

// Opens the Photo or Video Preview Modal from List Items
$(document).on('click', '[data-toggle=photo-modal], [data-toggle=video-modal]', function(e){
  e.preventDefault();
  const $this = $(this);
  const target = $this.data("target");
  const paginatedSetId: string = $this.parents("[data-paginated-set-id]").data("paginated-set-id");

  console.log(`opening modal for ${target} w/ set from ${paginatedSetId}`);

  let idSet = paginatedSets.get(paginatedSetId) || $this.closest("[data-basket-item-key]").toArray().map((i, e) => i.getAttribute("data-basket-item-key"));

  makeModal(target, true, idSet, false);
});


// Handle Share Form Submission
$(document).on('submit', 'form[data-intent=share]', function(e) {
  e.preventDefault();
  const form = $(this);
  const results = form.find('div#share-result');

  $.post(form.attr('action'), form.serialize())
    .done(function(message) {
      toastr.success(message);
    })
    .fail(function(data) {
      toastr.error("Your message did not send. Please make sure all form fields are filled out correctly, then try again.");
    });
});

// Admin Edit Popovers
$(document).popover({
  selector: '[data-edit-url]',
  container: 'body',
  placement: function() { return $(this.$element).data('edit-popover-position'); },
  html: true,
  template: '<div class="popover" role="tooltip"><div class="arrow"></div><div class="popover-content"></div></div>',
  trigger: 'hover',
  delay: { "show": 100, "hide": 800 },
  content: function() { return '<a target="_blank" href="' + $(this).data('edit-url') + '">Edit</a>'; }
});

// In guest basket, perform license agreement without initial redirect. The guest basket must be reached by POST.
$("a.download-button").on("click", function(e){
  if (window.location.href.match(/\/basket\/guest$/) && getCookie("newsroom.accepted_license") != "1") {
    e.preventDefault();
    const target = $(this).attr("href");

    $.get("/licenses/default")
      .done(function(modalBody) {
        $("#licenseModal").data("downloadUrl", target);
        $("#licenseModal .modal-dialog .modal-content").html(modalBody);
        $("#licenseModal").modal('show');
      });
  }
});


// ====================================================================================================================
// site-paginated-search.js
// ====================================================================================================================
function pageState() {
  let selectedTabId = $("li.content-type.active a").attr("href");
  if (selectedTabId !== undefined && selectedTabId.startsWith("#")) {
    selectedTabId = selectedTabId.slice(1)
  } else {
    selectedTabId = null;
  }

  return {
    query: $("#searchInput").val() || '',
    year: $("#filterForm #year").val() || '',
    month: $("#filterForm #month").val() || '',
    startYear: $("#filterForm input#startYear").val() || '',
    startMonth: $("#filterForm input#startMonth").val() || '',
    startDay: $("#filterForm input#startDay").val() || '',
    endYear: $("#filterForm input#endYear").val() || '',
    endMonth: $("#filterForm input#endMonth").val() || '',
    endDay: $("#filterForm input#endDay").val() || '',
    sortOrder: $("#filterForm input#sortOrder").val() || '',
    selectedContentType: $("#filterForm input#selectedContentType").val() || '',
    selectedTabId: selectedTabId
  }
}

function queryString(state) {
  let q = "";

  ['query',
    'year',
    'month',
    'startYear',
    'startMonth',
    'startDay',
    'endYear',
    'endMonth',
    'endDay',
    'sortOrder',
    'selectedContentType',
    'selectedTabId'].forEach(function(name) {
    if (state[name]) q += '&' + name + '=' + state[name];
  });

  return "?" + (q.length > 0 ? q.substring(1) : "");
}

function paginatedSearch(mergeState?) {
  window.location.href = queryString(Object.assign(pageState(), mergeState));
}

function updateSearchControls() {
  const urlParams = getUrlParams();
  $("#searchInput").val(urlParams.get('query'));
  $("#filterForm #year").val(urlParams.get('year'));
  $("#filterForm #month").val(urlParams.get('month'));
  $("#filterForm input#startYear").val(urlParams.get('startYear'));
  $("#filterForm input#startMonth").val(urlParams.get('startMonth'));
  $("#filterForm input#startDay").val(urlParams.get('startDay'));
  $("#filterForm input#endYear").val(urlParams.get('endYear'));
  $("#filterForm input#endMonth").val(urlParams.get('endMonth'));
  $("#filterForm input#endDay").val(urlParams.get('endDay'));

  const startDateFromQuery = urlParams.get('startMonth') + "/" + urlParams.get('startDay') + "/" + urlParams.get('startYear').substring(2, 4);
  const endDateFromQuery = urlParams.get('endMonth') + "/" + urlParams.get('endDay') + "/" + urlParams.get('endYear').substring(2, 4);

  if(startDateFromQuery != "//" && endDateFromQuery != "//") {
    $("#filterForm button#daterange-desktop span").text(startDateFromQuery + " - " + endDateFromQuery);
    $("#filterForm button#daterange-mobile").addClass("full-daterange");
  }
  $("#filterForm #sortOrder").val(urlParams.get('sortOrder'));
  $("#filterForm #selectedContentType").val(urlParams.get('selectedContentType'));
}

$(function() {
  $('li.next a.btn').on('click', function(e) {
    e.preventDefault();
    // save any selected IDs...

    // ...and get the next page of data
    const btn = $(this);
    const url = btn.attr('href');
    if (url !== '#') {
      window.location.href = url;
    }
  });

  updateSearchControls();

  $("ul#content-types li").on("click", function(e) {
    const newContentType = $(this).data("content-type");
    $("#filterForm input#selectedContentType").val(newContentType);

    // Add the selected tab to the href for each filtering option
    $("#filterForm ul#sort-options a").each(function(index) {
      $(this).attr("href", removeParameter($(this).attr("href"), "selectedContentType") + "&selectedContentType=" + newContentType);
    });
  });

  // let channelScopedSearch = false;

  // $("div.channel-scoped-search").on("click", function(e) {
  //   channelScopedSearch = true;
  //   const channelName = $("div.content").data("channel-name");
  //   const placeholder = $(this).data("placeholder");
  //   $("div.search-modal-dialog input").attr("placeholder", placeholder + " " + channelName + "...");
  //
  //   $("div.search-modal-dialog form").submit(function(e) {
  //     if(channelScopedSearch) {
  //       e.preventDefault();
  //       window.location.href = updateQueryStringParameter(window.location.pathname, "query", $("div.search-modal-dialog input").val());
  //     }
  //   });
  // });

  $("div#navbar a.searchbox-icon, button.search-entire-site").on("click", function(e) {
    // channelScopedSearch = false;
    const placeholder = $(this).data("placeholder");
    $("div.search-modal-dialog input").attr("placeholder", placeholder);
  });
});


// Handle "Load More" clicks
$(document).on("click", ".paginated-content-container a#next", function(e) {
  e.preventDefault();
  const $nextButton = $(this);
  const nextPage = $nextButton.attr("href");

  $nextButton.attr("disabled", "true");
  $nextButton.text("Loading...");

  $.get(nextPage, function(data) {
    const $newItems = $(data);
    const $container = $nextButton.closest(".paginated-content-container");
    const paginatedSetId = $container.data("paginated-set-id");

    $nextButton.closest(".footer-container").remove();

    if($container.hasClass("grid")) {
      $newItems.css('visibility', 'hidden');
      $container.append($newItems);

      // Append all of the newly loaded elements to the in-memory set of IDs used to manage the modal prev/next buttons
      const masonryElementIds = $newItems.filter((i, e) => e.classList !== undefined && e.classList.contains("grid__item") )
                                         .map((i, e) => e.getAttribute("data-basket-item-key")).toArray();

      paginatedSets.set(paginatedSetId,
                        paginatedSets.get(paginatedSetId).concat(masonryElementIds));

      imagesLoaded($container, () => {
        $newItems.css('visibility', 'visible');
        $container.masonry("appended", $newItems);
      });
    } else {
      $container.append($newItems);
    }
  });
});


// ====================================================================================================================
// Masonry Layout
// ====================================================================================================================

window.initMasonryGrids = function() {
  $('.grid').each(function(i, obj) {
    const $grid = $(obj);

    $grid.masonry({
      itemSelector: 'none', // select none at first
      columnWidth: '.grid__col-sizer',
      gutter: '.grid__gutter-sizer',
      horizontalOrder: true,
      percentPosition: true,
      stagger: 30,
      // nicer reveal transition
      visibleStyle: {transform: 'translateY(0)', opacity: 1},
      hiddenStyle: {transform: 'translateY(100px)', opacity: 0}
    });

    // initial items reveal
    imagesLoaded(obj,function() {
      $grid.removeClass('are-images-unloaded');
      $grid.masonry( 'option', { itemSelector: '.grid__item', transitionDuration: 0 });
      const $items = $grid.find('.grid__item');
      $grid.masonry( 'appended', $items);
    });

    $('li[data-content-type="photos-content"] a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
      $grid.masonry();
    });

    $('li[data-content-type="videos-content"] a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
      $grid.masonry();
    });

    $('li[data-content-type="photo"] a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
      $grid.masonry();
    });

    $('li[data-content-type="video"] a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
      $grid.masonry();
    });
  });
};

$(function() {
  window.initMasonryGrids();
});


// ====================================================================================================================
// login
// ====================================================================================================================
$(function() {
  $(".login-form").submit(() => {
    // Inject the guest basket data into the login form
    const hiddenBasketInput = $("<input type=\"hidden\" name=\"basketDocument\">");
    hiddenBasketInput.val(JSON.stringify(window.localBasketDataStore.document()));
    $(".login-form").append(hiddenBasketInput);
  });
});


// ====================================================================================================================
// Sticky Nav
// ====================================================================================================================
$(function() {
  const slideTriggerHeight = 100;
  const stickyNavBar: HTMLElement = document.getElementById("globalHeader");
  const stickyMarketNavBar: HTMLElement = document.getElementById("globalMarketHeader");

  let lastPosition = window.scrollY;
  let ticking = false;
  let scrollingDown = false;
  let navVisible = true;

  function pageScroll(scrollPosition) {
    if(scrollingDown) {
      if (lastPosition > slideTriggerHeight) {
        stickyNavBar.classList.add("hide-nav");
        if (stickyMarketNavBar !== null) {
          stickyMarketNavBar.classList.add("hide-market-nav");
        }
        navVisible = false;
      }
    } else {
      stickyNavBar.classList.remove("hide-nav");
      if (stickyMarketNavBar !== null) {
        stickyMarketNavBar.classList.remove("hide-market-nav");
      }
      navVisible = true;
    }
  }

  window.addEventListener('scroll', function(e) {
    scrollingDown = window.scrollY > lastPosition;
    lastPosition = window.scrollY;

    if (!ticking) {
      window.requestAnimationFrame(function() {
        pageScroll(lastPosition);
        ticking = false;
      });

      ticking = true;
    }
  });
});

// ====================================================================================================================
// Press Kit Nav Panel
// ====================================================================================================================
$(function() {
  const $stickyPanel = $(".presskit-nav__panel");
  if ($stickyPanel.length > 0) {
    Stickyfill.add($stickyPanel);

    const $presskitNav = $(".presskit-nav");
    const $presskitNavList = $(".presskit-nav__list");
    const $presskitSections = $(".release-body h2 a");

    if($presskitSections.length > 0) {
      $presskitNav.addClass("is-active");

      $presskitSections.each(function() {
        let $anchor = $(this);
        let parentText = $(this).parents("h2").text();
        let navText;

        if(typeof(parentText) === "string") {
          let titleComponents = parentText.split(/(:|\s-+\s)/, 3);

          if(titleComponents.length === 3) {
            navText = titleComponents[2];
          } else {
            navText = parentText;
          }
        }

        $presskitNavList.append($("<li class=\"presskit-nav__list__item\"><a href=\"#" + $anchor.attr("id") + "\" class=\"presskit-nav__link\">" + navText + "</a></li>"));
      });

      $(document).on('click', '.presskit-nav__panel', function() {
        if(this.classList.contains("is-expanded")) {
          this.classList.remove("is-expanded");
        } else {
          this.classList.add("is-expanded");
        }

        Stickyfill.refreshAll();
      });
    } else {
      console.log("No press kit sections found.")
    }
  }
});


// ====================================================================================================================
// Basket Share
// ====================================================================================================================
$(document).on("click", "#share-basket-submit", function(e){
  e.preventDefault();
  const $form = $(this).closest("form");
  const $button = $("#share-basket-submit");
  const $wait = $("#share-basket-submit-wait");

  $wait.removeClass("hidden");
  $button.attr("disabled", "disabled");

  $("#share-basket-modal").modal("hide");

  $.ajax({
    url: $form.attr("action"),
    data: $form.serialize(),
    method: "POST",
    dataType: "json",
    error: function(xhr) {
      $wait.addClass("hidden");
      $button.removeAttr("disabled");

      const result = xhr.responseJSON;
      $("#share-basket-modal").find(".error").each(function(_, elem) {
        if ($(elem).is("span")) $(elem).remove();
        else $(elem).removeClass("error");
      });

      if (result.status === "KO") {
        $.each(result.message, function(field, message) {
          if (field === "") {
            $("#share-basket-modal ul label").addClass("error");
            $("#share-basket-modal .form-errors").append("<span class='error'>"+message+"</span>");
          }
          else {
            $(`[name='${String(field)}']`).addClass("error").after("<span class='error'>"+message+"</span>");
          }
        });
      }
    },
    success: function(result) {
      $form.find('[name="share-to"]').val("");
      toastr.success(result.message, "Success", {timeOut: 0, extendedTimeOut: 0});
    }
  }).done(() => {
    $wait.addClass("hidden");
    $button.removeAttr("disabled");
  });
});


// ====================================================================================================================
//
// ====================================================================================================================

$(() => {
  $(document).on("click", ".download-overlay__link", function(e) {
    $(e.currentTarget).parents(".download-overlay").collapse('hide');
  });

  $('#quick-links').on("click",function(e){
    e.preventDefault();
    $(".site-footer-container--links").toggleClass("is-expanded");
  });

  $("ul#sort-options a[href!='#']").on("click", function (e) {
    e.preventDefault();
    e.stopPropagation();

    paginatedSearch({sortOrder: $(e.target).data("sort-order")});
  });

  $(".release-grid-indicator").on("click", (e) => {
    $(e.target)
      .parents('.release-list')
      .removeClass('release-list')
      .addClass('release-grid');

    createCookie('homepage-release-format', 'release-grid', 9999);
  });

  $(".release-list-indicator").on("click", (e) => {
    $(e.target)
      .parents('.release-grid')
      .removeClass('release-grid')
      .addClass('release-list');

    createCookie('homepage-release-format', 'release-list', 9999);
  });


  // Trigger bootstrap dropdown menus on mouse over

  $('.market-navbar [data-toggle="dropdown"]').on('mouseenter', e => {
    e.stopPropagation();
    let $target = $(e.target);
    if ($target.siblings(".dropdown-menu").is(":hidden")) {
      $target.dropdown('toggle');
    }
  });

  $(".market-channel__container--level2").on("mouseleave", function() {
    let $child = $(this);
    setTimeout(function () {
      $child.parents(".market-channel__item--level1").removeClass("open");
    }, 500);
  });

});
