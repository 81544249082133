import $ from "jquery";
import { createCookie } from "./util/cookies"

if(window.Intl !== undefined) {
  const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // modern browsers
  if(zone !== undefined) {
    createCookie('TZ', encodeURIComponent(zone), 7);
  } else {
    // no time zone edge case
    createCookie('TZ', -1 * new Date().getTimezoneOffset(), 7);
  }
} else {
  // IE 10
  createCookie('TZ', -1 * new Date().getTimezoneOffset(), 7);
}

// For timezone detection on the server
// NP-42 Post-login, resume a download that required a login

$(function() {
  const $head = $("head");
  const destination = $head.data("post-login-download");

  if(destination !== "#" && destination !== undefined && !/\/login$/.test(window.location.href) && $("a#logout-link").length > 0) {
    window.location.href = destination;
  }
});
