import $ from "jquery";
import {createCookie} from "./util/cookies";

(function(window, document, $, localStorage) {

  var BasketItem = function(type, key) {
    this.itemType = type;
    this.itemKey = key;

    this.containerKey = type.toLowerCase() + "s"
  }

  BasketItem.fromElement = function(element) {
    return new BasketItem(element.data("basket-item-type"), element.data("basket-item-key"));
  }

  // Manages basket items in HTML5 localStorage
  var LocalBasketStore = function() {
    var storageKey = "basket"
    var data;
    var that = this;

    var blankDocument = function() {
      return {
        "_id": "guest-basket",
        "photos": [],
        "releases": [],
        "collections": [],
        "videos": [],
        "lastModified": "1999-12-31T23:59:59.999Z" // This date value is not important, it just needs to be some actual date so the JSON can be properly deserialized.
      }
    };

    try {
      data = JSON.parse(localStorage.getItem(storageKey));
      if (data === null || (typeof data !== "object")) {
        data = blankDocument();
      }
    } catch(e) {
      data = blankDocument();
    }

    this.document = function() {
      return data;
    };

    this.revision = data._rev;

    var save = function() {
      console.log("Saving Basket to localStorage", data);

      localStorage.setItem(storageKey, JSON.stringify(data));
    };

    this.onLoad = function(callback) {
      callback();
    };

    this.match = function(basketItems, callback) {
      var matchingItems = basketItems.filter(function(basketItem) {
        return data[basketItem.containerKey] !== undefined &&
          data[basketItem.containerKey].indexOf(basketItem.itemKey) >= 0;
      });

      callback(matchingItems);
    };

    this.count = function(containerKey) {
      if (containerKey === undefined) {
        return data["photos"].length + data["videos"].length + data["releases"].length + data["collections"].length;
      } else {
        return data[containerKey].length;
      }
    };

    this.add = function(basketItem, callback) {
      console.log("LocalBasketStore#add ", basketItem);

      basketItem.itemKey.split(" ").forEach(function(id){
        if (data[basketItem.containerKey].indexOf(id) < 0) {
          data[basketItem.containerKey].push(id);
          save();

          if (callback !== undefined) { callback(that); }
        }
      });
    };

    this.remove = function(basketItem, callback) {
      console.log("LocalBasketStore#remove ", basketItem);
      data[basketItem.containerKey].splice(data[basketItem.containerKey].indexOf(basketItem.itemKey), 1);
      save();

      if (callback !== undefined) { callback(that); }
    };

    this.replace = function(newData) {
      data = newData;

      save();
    };

    this.contains = function(basketItem) {
      return data[basketItem.containerKey].indexOf(basketItem.itemKey) > -1
    };

    this.clear = function(containerKey, contentIds) {
      if (containerKey === undefined) {
        data = blankDocument();
      } else {
        console.log(contentIds);
        data[containerKey] = [];
        // if(tabId !== undefined) {
        //   var newContainerContents = [];
        //   $.each(data[containerKey], function(i, v) {
        //     if(contentIds.indexOf(v) === -1) {
        //       newContainerContents.push(v);
        //     }
        //   });
        //   data[containerKey] = newContainerContents;
        // } else {
        //   data[containerKey] = [];
        // }
      }

      save();

      return $.Deferred(function(defer) {

      }).resolve().promise();
    };

    this.post = function(url, name) {
      name = name || "basket";
      var basket = $.extend(true, { _id: "guest", lastModified: new Date().toISOString() }, data);

      var $form = $("<form/>", { method: "post", action: url });
      $form.append($("<input>", { type: "hidden", name: name, value: JSON.stringify(basket) }));
      $form.appendTo('body').submit();
    }

  };
  window.LocalBasketStore = LocalBasketStore;

  // Manages basket items in both HTML5 localStorage and via a JSON API
  var RemoteBasketStore = function(localBasketStore) {
    var that = this;

    var basketRoutePrefix = "";
    var marketPrefix = $("body").data("market-route-prefix");
    if (typeof(marketPrefix) === "string") {
      basketRoutePrefix = "/" + marketPrefix;
      console.info("MarketPrefix found body[data-market-route-prefix]: " + marketPrefix);
    } else {
      console.warn("Market Prefix not found in body[data-market-route-prefix]");
    }

    // When we start up, ask for the remote basket document.
    var loader = $.get(basketRoutePrefix + "/basket.json?rev=" + localBasketStore.revision)
      .done(function(response) {
        localBasketStore.replace(response);
      });

    this.onLoad = function(callback) {
      loader.done(function(response) { callback() })
    };

    this.match = function(basketItems, callback) {
      loader.done(function(response) {
        localBasketStore.match(basketItems, callback)
      })
    };

    this.count = function(containerKey) {
      return localBasketStore.count(containerKey);
    };

    this.add = function(basketItem, callback) {
      console.log("RemoteBasketStore#add ", basketItem);

      $.ajax({
          url: basketRoutePrefix + "/basket/items.json",
          type: "POST",
          data: JSON.stringify(basketItem),
          contentType: "application/json; charset=utf-8",
          dataType: "json"
      }).done(function(response) {
          localBasketStore.replace(response);
          callback(that);
         })
    };

    this.remove = function(basketItem, callback) {
      console.log("RemoteBasketStore#remove ", basketItem);

      $.ajax({
          url: basketRoutePrefix + "/basket/items/remove.json",
          type: "POST",
          data: JSON.stringify(basketItem),
          contentType: "application/json; charset=utf-8",
          dataType: "json"
      }).done(function(response) {
          localBasketStore.replace(response);
          callback(that);
         })
    };

    this.contains = function(basketItem) {
      return localBasketStore.contains(basketItem);
    };

    this.clear = function(containerKey, clearIds) {
      console.log("RemoteBasketStore#clear");

      if (containerKey === undefined) {
        return $.get(basketRoutePrefix + "/basket/clear").done(function(response) {
          localBasketStore.replace(response)
        })
      } else {
        if(clearIds === undefined) {
          return $.get(basketRoutePrefix + "/basket/" + containerKey + "/clear").done(function(response) {
            localBasketStore.replace(response)
          })
        } else {
          return $.get(basketRoutePrefix + "/basket/" + containerKey + "/clear?clearIds=" + clearIds).done(function(response) {
            localBasketStore.replace(response)
          })
        }
      }
    }

  };
  window.RemoteBasketStore = RemoteBasketStore;

  var Basket = function(dataStore) {

    $.fn.enableBasketRemover = function() {
      this.each(function() {
          $(this)
              .find("[data-basket-action=\"add\"]").addClass("basket-action-hidden").removeClass("basket-action-visible")
              .siblings("[data-basket-action=\"remove\"]").addClass("basket-action-visible").removeClass("basket-action-hidden");
      });
    };

    var enableBasketRemover = function(basketItem) {
      basketItem.itemKey.split(" ").forEach(function(id){
        $("[data-basket-item-key=\"" + id + "\"]").enableBasketRemover();
      });
    };

    this.enableBasketRemover = enableBasketRemover;

    var enableBasketAdder = function(basketItem) {
      $("[data-basket-item-key=\"" + basketItem.itemKey + "\"]")
        .find("[data-basket-action=\"remove\"]").addClass("basket-action-hidden").removeClass("basket-action-visible")
        .siblings("[data-basket-action=\"add\"]").addClass("basket-action-visible").removeClass("basket-action-hidden");
    };

    this.enableBasketAdder = enableBasketAdder;

    // "Alias" clicks to our custom event handlers for basket-add and basket-remove
    $(document).on("click", "[data-basket-action]", function(e) {
      var action = $(this).data('basket-action');

      if (action === "add" || action === "remove") {
        var basketItem = BasketItem.fromElement($(this).parents("[data-basket-item-key]"));
        $(document).trigger('basket.' + action, [basketItem])
      }

      return true;
    });

    $(document).on("basket.add", function(e, basketItem) {
      console.log("basket.add", basketItem);
      dataStore.add(basketItem, function(store) {
        $(document).trigger("basket.change", [store.count()]);
      });
      enableBasketRemover(basketItem);
    });

    $(document).on("basket.remove", function(e, basketItem) {
      console.log("basket.remove", basketItem);
      dataStore.remove(basketItem, function(store) {
        $(document).trigger("basket.change", [store.count()]);
      });
      enableBasketAdder(basketItem);
    });

    this.enableBasketRemoverForAllItemsOnPage = function() {
      // Scan the page for all items that are able to be added to/removed from the basket
      var itemsOnPage = $("[data-basket-item-key]").map(function(i, e) {
        return BasketItem.fromElement($(e))
      }).toArray();

      // Ask `dataStore` for the list of keys in the basket
      dataStore.match(itemsOnPage, function(matchingBasketItems) {
        $(document).trigger("basket.change", [dataStore.count()]);

        // `dataStore` fires off the callback when its found matches between itemsOnPage and
        // it's own internal set.
        matchingBasketItems.forEach(function(basketItem) {
          enableBasketRemover(basketItem);
        })
      });
    };

    this.enableBasketRemoverForAllItemsOnPage();

    this.onReady = function(callback) {
      var basket = this;

      dataStore.onLoad(function(response) {
        callback(basket);
      })
    };

    this.count = function(containerKey) {
     return dataStore.count(containerKey)
    };

    this.contains = function(basketItem) {
      return dataStore.contains(basketItem);
    };

    this.clear = function(containerKey, contentIds) {
      var clearPromise = dataStore.clear(containerKey, contentIds);

      return clearPromise.done(function() {
        $(document).trigger("basket.change", [dataStore.count()]);
        $(document).trigger("basket.clear", [containerKey, contentIds]);
        document.location.reload();
      })
    }
  };

  window.Basket = Basket;

  $.fn.enableBasketControls = function(e){
    this.each(function(){
      $(this).find("[data-basket-item-key]").addBack("[data-basket-item-key]").each(function(){
        var basketItem = BasketItem.fromElement($(this));
        if (window.basket.contains(basketItem)) {
          $(this).enableBasketRemover(basketItem);
        }
      });
    });
    return this;
  }

  $("a#empty-basket").on("click",function(e){
    window.basket.clear().done(function() {
      document.location.reload();
    });
  });

  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
  }

  $(document).on("click", "a.basket-download", function(e){
    var isGuestBasket = $(this).hasClass("license-check-post-download");
    var basketHref = $(this).attr("href");
    if (getCookie("newsroom.accepted_license") !== "1") {
      e.preventDefault();
      var target = $(this).attr("href");

      $.get("/licenses/default")
        .done(function(modalBody) {
          $("#licenseModal").data("downloadUrl", target);
          $("#licenseModal .modal-dialog .modal-content").html(modalBody);
          $("#licenseModal").modal('show');

          $("#licenseModal div.modal-footer .btn-primary").click(function(e){
            e.preventDefault();
            createCookie("newsroom.accepted_license", "1");

            if(isGuestBasket) {
              window.localBasketDataStore.post(basketHref);
            } else {
              window.location.href = basketHref;
            }

            $("#licenseModal").modal('hide');
          });
        });

      return false;
    } else {
      if (basketHref != null && basketHref.indexOf("/guest") != -1 && window.localBasketDataStore) {
        window.localBasketDataStore.post(basketHref);
        return false;
      }
      else return true;
    }
  });

  // empty basket localstorage before logging out
  $(document).on("click", "a#logout-link", function(e){
      var logoutLink = $(this);
     e.preventDefault();
      window.localBasketDataStore.clear();
      window.location.href = logoutLink.attr("href");
  });

})(window, document, $, window.localStorage);
